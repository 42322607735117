@import 'variables';
@import '~@jumpventures/stack/src/scss/app';

// toplevel
@import "toplevel/fonts";

// components
@import 'components/logo';
@import 'components/client-action';

.btn--with-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn__icon {
  margin-right: 0.5rem;
}

.entity-overview {
  th:first-child {
    padding-left: calc(40px + 2.5rem);
  }
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

button.block {
  width: 100%;
}

.section + .section {
  margin-top: 2rem;
}


.search-filter {
  @extend .form-control;
  padding: 0 50px 0 0;
  position: relative;
  max-width: 100%;
  width: 460px;

  &.has-focus {
    border-color: $primary;
  }

  input, button {
    background: transparent;
    border: none;
  }

  input {
    color: #fff;
    padding: $input-padding-y 0 $input-padding-y $input-padding-x;
    outline: none;
    width: 100%;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 50px;
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    color: $primary;
    stroke: currentColor;

    svg {
      transition: transform 0.2s;
    }

    &:active {
      svg {
        transform: scale(1.1);
      }
    }
  }

}

.sidebar-header {
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid #fff;
  margin-top: 5rem;

  svg {
    margin-right: 0.5rem;
    width: 1.125rem;
    stroke: currentColor;
    height: 1.125rem;
  }
}
