.client-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: -6px 0;
}

.client-action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 6px 0;
  border-color: transparent;
  font-weight: normal;
  background-color: $dark-medium;

  .btn__icon > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 100%;
    height: 100%;
    stroke: currentColor;
  }

  &--red {
    color: $red;

    &:hover,
    &:focus {
      border-color: $red;
      background-color: $red;
      color: $dark-medium;
    }
  }

  &--green {
    color: $green;

    &:hover,
    &:focus {
      border-color: $green;
      background-color: $green;
      color: $dark-medium;
    }
  }
}
