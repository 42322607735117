@font-face {
  font-family: 'GT Walsheim';
  src:
    url('../../fonts/GT-Walsheim-Bold.woff2') format('woff2'),
    url('../../fonts/GT-Walsheim-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'GT Walsheim';
  src:
          url('../../fonts/GT-Walsheim-Regular.woff2') format('woff2'),
          url('../../fonts/GT-Walsheim-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'GT Walsheim';
  src:
          url('../../fonts/GT-Walsheim-Light.woff2') format('woff2'),
          url('../../fonts/GT-Walsheim-Light.woff') format('woff');
  font-weight: 300;
}
