// colors
$body-bg:               #050A12 !default;
$body-color:            rgba(#fff, 87%) !default;
$dark:                  #181A20 !default;
$gray-500:              #2B2B2E !default;
$dark-medium:           $gray-500 !default;
$section-color:         $body-color !default;

$primary:               #0066FF !default;
$primary-hover:         #003080 !default;
$secondary:             #1CF3D4 !default;
$light:                 mix(#fff, $dark-medium, 50%) !default;
$lightest:              mix(#fff, $dark-medium, 25%) !default;

$red:                   #F5525C !default;
$yellow:                #FFA645 !default;
$green:                 #1CF3D4 !default;
$purple:                #9B51E0 !default;


// forms
$input-disabled-bg:           lighten($gray-500, 10%) !default;

