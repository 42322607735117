// todo global styling
@import 'assets/scss/styles';

html, body {
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root.flex-content {
  display: flex;
  flex-direction: column;
  flex: 1;

  .auth {
    flex: 1;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=file] {
  width: 100%;
}


.navbar-brand {
  font-weight: bold;
}

.logo svg {
  margin-top: 1rem;
  width: 60px;
  height: 60px;
}
